/**
 * Created By : Venkat Salagrama 
 */

import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { RouterModule, Routes, Router } from '@angular/router';
import { ValidationService } from '../../services/config/config.service';
import { UserService } from '../../services/user/user.service';
import { ToastrService } from 'ngx-toastr';
import { routerTransition } from '../../services/config/config.service';

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.css'],
	animations: [routerTransition()],
	host: { '[@routerTransition]': '' }
})
export class LoginComponent implements OnInit {
	loginForm: FormGroup;

	constructor(private formBuilder: FormBuilder, private router: Router, private userService: UserService, private toastr: ToastrService) {
		this.loginForm = this.formBuilder.group({
			email: ['', [Validators.required]],
			password: ['', [Validators.required]]
		});
	}

	// Check if user already logged in
	ngOnInit() {
		if (localStorage.getItem('userData')) {
			this.router.navigate(['/']);
		}
	}

	// Initicate login
	doLogin() {
		this.userService.doLogin(this.loginForm.value).subscribe(res => {

			// sessionStorage.setItem('username', this.loginForm.value.email);
			// sessionStorage.setItem('bearerToken', res.token);

			localStorage.setItem('userData', res.token);	
			this.userService.getUserInfo(res.token).subscribe(resp => {
				localStorage.setItem('userInfo', JSON.stringify(resp));
			}, (e) => {
			});

			this.router.navigate(['/']);
			this.toastr.success('Success', 'Logged In Successfully');	

		}, (e) => {
			console.log(e.error);
			if (e.error != null && e.error != 'undefined' && e.error === 'ACCOUNT_LOCKED') {
				this.toastr.error('Failed', 'Account was locked. Please contact administrator');
			} else {
				this.toastr.error('Failed', 'Invalid Credentials');
			} 
		});
	}
}

/**
 * Created By : Venkat Salagrama 
 */
