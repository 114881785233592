export const APP_SETTINGS = {
    ENVIROCOM_API_ENDPOINT: '',
    ORGANIZATION_ID: '',
    PROJECT_ID: '',
    PROJECT_ID_BEACH_REPORTS: '',
    PROJECT_ID_CONDITIONS_REPORT: '',
    PROJECT_ID_EQUIPMENT_REPORT: '',
    PROJECT_ID_INCIDENT_REPORT: '',
    LIFEGUARD_BEACH_DATA: '',
    LIFEGUARD_INCIDENT_DATA: '',
    LIFEGUARD_CONDITIONS_DATA: '',
    LIFEGUARD_EQUIPMENT_DATA: '',
    GOLDCOAST_SEAWAY_TIDE_GAUGE: '',
    GOLDCOAST_WAVE_BUOY: ''
  };