/**
 * Created By : Venkat Salagrama 
 */

 import { Injectable } from '@angular/core';
 import { HttpClient, HttpHeaders } from '@angular/common/http';
 import { map } from 'rxjs/operators';
 import { first } from 'rxjs/operators';
 import { BehaviorSubject, Observable, Subject } from 'rxjs';
 import { ApiEndpoints } from '../../configs/api-endpoints';
 import * as moment from 'moment-timezone';
 import { APP_SETTINGS } from "src/app/models/app-settings";

 @Injectable()
export class ApiService {

    constructor(private http: HttpClient) { 
    }

    /**
     * 
     * @returns 
     */
    getTowers(): Observable<any> {
        const headers = new HttpHeaders({
            authorization : 'Bearer ' + localStorage.getItem('userData')
		 });

        return this.http.get<any>(ApiEndpoints.TOWERSLIST, {headers: headers})
        .pipe(map(towers => {			
            return towers;
        }));
	}
	
    /**
     * 
     * @param selectedtowerid 
     * @param selecteddate 
     * @returns 
     */
    fetchBeachReport(selectedtowerid: number, selecteddate: string, datasetselected: string): Observable<any> {
        const headers = new HttpHeaders({
            authorization : 'Bearer ' + localStorage.getItem('userData')
		 });

        return this.http.get<any>(ApiEndpoints.BEACHREPORTFETCH+datasetselected+"?endTime="+selecteddate+"&limit=25&offset=0&order=TimeAsc&startTime="+selecteddate+"&state=Visible", {headers: headers})
        .pipe(map(beachreport => {			
            return beachreport;
        }));
    }

    fetchConditionReport(selectedtowerid: number, selecteddate: string, datasetselected: string): Observable<any> {
        const headers = new HttpHeaders({
            authorization : 'Bearer ' + localStorage.getItem('userData')
		 });

        var startDate = selecteddate.replace("14:00:00.000Z", "00:00:01.000Z");
        var endDate = selecteddate.replace("14:00:00.000Z", "23:59:59.000Z");

        if(!startDate.includes("T")) startDate = startDate+"T00:00:01.000Z";
        if(!endDate.includes("T")) endDate = endDate+"T23:59:59.000Z";

        // return this.http.get<any>(ApiEndpoints.BEACHREPORTFETCH+datasetselected+"?endTime="+endDate+"&limit=1&offset=0&order=TimeDesc&startTime="+startDate+"&state=Visible", {headers: headers})
        // .pipe(map(beachreport => {			
        //     return beachreport;
        // }));

        return this.http.get<any>(ApiEndpoints.BEACHREPORTFETCH+datasetselected+"?limit=1&offset=0&order=TimeDesc&state=Visible", {headers: headers})
        .pipe(map(beachreport => {			
            return beachreport;
        }));
    }

    fetchEquipmentReport(selectedtowerid: number, selecteddate: string, datasetselected: string): Observable<any> {
        const headers = new HttpHeaders({
            authorization : 'Bearer ' + localStorage.getItem('userData')
		 });

        var startDate = selecteddate.replace("14:00:00.000Z", "00:00:01.000Z");
        var endDate = selecteddate.replace("14:00:00.000Z", "23:59:59.000Z");

        if(!startDate.includes("T")) startDate = startDate+"T00:00:01.000Z";
        if(!endDate.includes("T")) endDate = endDate+"T23:59:59.000Z";
        
        // return this.http.get<any>(ApiEndpoints.BEACHREPORTFETCH+datasetselected+"?endTime="+endDate+"&limit=1&offset=0&order=TimeDesc&startTime="+startDate+"&state=Visible", {headers: headers})
        // .pipe(map(beachreport => {			
        //     return beachreport;
        // }));

        return this.http.get<any>(ApiEndpoints.BEACHREPORTFETCH+datasetselected+"?limit=1&offset=0&order=TimeDesc&state=Visible", {headers: headers})
        .pipe(map(beachreport => {			
            return beachreport;
        }));
    }

    /**
     * 
     * @param selectedtowerid 
     * @param startDatetime 
     * @param endDatetime 
     * @param datasetselected 
     * @returns 
     */
    fetchIncidentReports(selectedtowerid: number, startDatetime: string, endDatetime: string, datasetselected: string): Observable<any> {
        const headers = new HttpHeaders({
            authorization : 'Bearer ' + localStorage.getItem('userData')
		 });

        return this.http.get<any>(ApiEndpoints.BEACHREPORTFETCH+datasetselected+"?endTime="+endDatetime+"&limit=25&offset=0&order=TimeAsc&startTime="+startDatetime+"&state=Visible", {headers: headers})
        .pipe(map(beachreport => {			
            return beachreport;
        }));
    }

    /**
     * 
     * @returns 
     */
    fieldmappingOnBeachReport(datasetselected: string): Observable<any> {
        const headers = new HttpHeaders({
            authorization : 'Bearer ' + localStorage.getItem('userData')
		 });

        return this.http.get<any>(ApiEndpoints.BEACHREPORTFIELDMAPPINGS+datasetselected, {headers: headers})
        .pipe(map(beachreport => {			
            return beachreport;
        }));
    }

    /**
     * 
     * @param formData 
     * @param timestamp 
     * @returns 
     */
    postBeachReport(formData: FormData, timestamp: string, datasetselected: string, datasetname: string): Observable<any> {

        const headers = new HttpHeaders({
            authorization : 'Bearer ' + localStorage.getItem('userData')
		 });

        return this.http.post<any>(ApiEndpoints.BEACHREPORTPOST+datasetselected+'/'+timestamp+'/'+datasetname, formData, {headers: headers})
        .pipe(map(resp => {			
            return resp;
        }));

    }

    /**
     * 
     * @param formData 
     * @param timestamp 
     * @param datasetselected 
     * @param datasetname 
     * @returns 
     */
    postIncidentReport(formData: FormData, timestamp: string, datasetselected: string, datasetname: string): Observable<any> {

        const headers = new HttpHeaders({
            authorization : 'Bearer ' + localStorage.getItem('userData')
		 });

        return this.http.post<any>(ApiEndpoints.BEACHREPORTPOST+datasetselected+'/'+timestamp+'/'+datasetname, formData, {headers: headers})
        .pipe(map(resp => {			
            return resp;
        }));

    }

    postConditionReport(formData: FormData, timestamp: string, datasetselected: string): Observable<any> {

        const headers = new HttpHeaders({
            authorization : 'Bearer ' + localStorage.getItem('userData')
		 });

        return this.http.post<any>(ApiEndpoints.BEACHREPORTPOST+'save/'+datasetselected+'/'+timestamp, formData, {headers: headers})
        .pipe(map(resp => {			
            return resp;
        }));

    }

    postEquipmentReport(formData: FormData, timestamp: string, datasetselected: string): Observable<any> {

        const headers = new HttpHeaders({
            authorization : 'Bearer ' + localStorage.getItem('userData')
		 });

        return this.http.post<any>(ApiEndpoints.BEACHREPORTPOST+'save/'+datasetselected+'/'+timestamp, formData, {headers: headers})
        .pipe(map(resp => {			
            return resp;
        }));

    }

    fetchDatasetByTowerId():  Observable<any> {

        const headers = new HttpHeaders({
            authorization : 'Bearer ' + localStorage.getItem('userData')
		 });

        return this.http.get<any>(ApiEndpoints.DATASETBYTOWER, {headers: headers})
        .pipe(map(resp => {			
            return resp;
        }));

    }


    fetchDatasetByTowerIdAndLocation(towerId: string, typeOfReport: string):  Observable<any> {

        const headers = new HttpHeaders({
            authorization : 'Bearer ' + localStorage.getItem('userData')
		 });

        if(typeOfReport === "beach") {

            return this.http.get<any>(ApiEndpoints.DATASETBYTOWERFORBEACHREPORT+"&location="+towerId, {headers: headers})
            .pipe(map(resp => {			
                return resp;
            }));

        } else if(typeOfReport === "condition") {

            return this.http.get<any>(ApiEndpoints.DATASETBYTOWERFORCONDITIONREPORT+"&location="+towerId, {headers: headers})
            .pipe(map(resp => {			
                return resp;
            }));

        } else if(typeOfReport === "equipment") {

            return this.http.get<any>(ApiEndpoints.DATASETBYTOWERFOREQUIPMENTREPORT+"&location="+towerId, {headers: headers})
            .pipe(map(resp => {			
                return resp;
            }));

        } else if(typeOfReport === "incident") {

            return this.http.get<any>(ApiEndpoints.DATASETBYTOWERFORINCIDENTREPORT+"&location="+towerId, {headers: headers})
            .pipe(map(resp => {			
                return resp;
            }));

        }

    }


    imageView(datasetselected: string, formField: string, beachReportId: string): Observable<any> {
        const headers = new HttpHeaders({
            authorization : 'Bearer ' + localStorage.getItem('userData')
		 });

        return this.http.get<any>(ApiEndpoints.IMAGEPREVIEW+datasetselected+"?field="+formField+"&id="+beachReportId, {headers: headers})
        .pipe(map(imagedata => {			
            return imagedata;
        }));
    }

    videoView(datasetselected: string, formField: string, beachReportId: string): Observable<any> {
        const headers = new HttpHeaders({
            authorization : 'Bearer ' + localStorage.getItem('userData')
		 });

        return this.http.get<any>(ApiEndpoints.VIDEOPREVIEW+datasetselected+"?field="+formField+"&id="+beachReportId, {headers: headers})
        .pipe(map(imagedata => {			
            return imagedata;
        }));
    }

    fetchTideDetails(): Observable<any> {
        const headers = new HttpHeaders({
            authorization : 'Bearer ' + localStorage.getItem('userData')
		 });

        var currentDateTime = new Date();		
		var aestCreatedEndDate = moment.utc(currentDateTime).tz("Australia/Brisbane").format("yyyy-MM-DDTHH:mm:ss");
        var aestCreatedStartDate = moment(aestCreatedEndDate).subtract(10, 'months').format("yyyy-MM-DDTHH:mm:ss");
        aestCreatedEndDate = aestCreatedEndDate + ".000Z"; 
        aestCreatedStartDate = aestCreatedStartDate + ".000Z"; 

        return this.http.get<any>(ApiEndpoints.BEACHREPORTFETCH+APP_SETTINGS.GOLDCOAST_SEAWAY_TIDE_GAUGE+"?endTime="+aestCreatedEndDate+"&limit=4&offset=0&order=TimeDesc&startTime="+aestCreatedStartDate+"&state=Visible", {headers: headers})
        .pipe(map(beachreport => {			
            return beachreport;
        }));
    }

    fetchDataset(towerid) {
		localStorage.removeItem('brdataset'); 
		localStorage.removeItem('irdataset'); 
		localStorage.removeItem('codataset'); 
        localStorage.removeItem('eqdataset');
		this.fetchDatasetByTowerId().subscribe(res => {      
			for(var i=0;i<res.value.results.length;i++) {
				// if(res.value.results[i].location == towerid && res.value.results[i].name === LIFEGUARD_BEACH_DATA) {
                if(res.value.results[i].location == towerid && 
                    res.value.results[i].name.toLowerCase().includes(APP_SETTINGS.LIFEGUARD_BEACH_DATA.toLowerCase())) {
					localStorage.setItem('brdataset', res.value.results[i].id+"");
				}

				// if(res.value.results[i].location == towerid && res.value.results[i].name === LIFEGUARD_INCIDENT_DATA) {
                if(res.value.results[i].location == towerid && 
                    res.value.results[i].name.toLowerCase().includes(APP_SETTINGS.LIFEGUARD_INCIDENT_DATA.toLowerCase())) {
					localStorage.setItem('irdataset', res.value.results[i].id+"");
				}

				// if(res.value.results[i].location == towerid && res.value.results[i].name === LIFEGUARD_CONDITIONS_DATA) {
                if(res.value.results[i].location == towerid && 
                    res.value.results[i].name.toLowerCase().includes(APP_SETTINGS.LIFEGUARD_CONDITIONS_DATA.toLowerCase())) {
					localStorage.setItem('codataset', res.value.results[i].id+"");
				}

                // if(res.value.results[i].location == towerid && res.value.results[i].name === LIFEGUARD_EQUIPMENT_DATA) {
                if(res.value.results[i].location == towerid && 
                    res.value.results[i].name.toLowerCase().includes(APP_SETTINGS.LIFEGUARD_EQUIPMENT_DATA.toLowerCase())) {
					localStorage.setItem('eqdataset', res.value.results[i].id+"");
				}
			}
		}, (e) => {			
			console.log(e);       
		});
	}

    forgotPasswordUserVerification(username: string): Observable<any> {

        const headers = new HttpHeaders({        
		});

        return this.http.get<any>(ApiEndpoints.FORGOTPWDVERIFICATION+"?username="+username, {headers: headers})
        .pipe(map(userdata => {			
            return userdata;
        }));
    }

    forgotPasswordOtpValidation(username: string, otp: string): Observable<any> {

        const headers = new HttpHeaders({        
		});

        return this.http.get<any>(ApiEndpoints.FORGOTPWDEMAILOTPVALIDATION+"?username="+username+"&otp="+otp, {headers: headers})
        .pipe(map(resdata => {			
            return resdata;
        }));
    }

    forgotPasswordUpdatePassword(username: string, password: string): Observable<any> {

        const headers = new HttpHeaders({        
		});

        /*return this.http.get<any>(ApiEndpoints.FORGOTPWDUPDATEPASSWORD+"?username="+username+"&password="+password, {headers: headers})
        .pipe(map(resdata => {			
            return resdata;
        }));*/

        return this.http.post<any>(ApiEndpoints.FORGOTPWDUPDATEPASSWORD, { username: username, password: password })
		  .pipe(map(resdata => {
			return resdata;
		  }));
    }

    fetchWaveBuoyDetails(): Observable<any> {
        const headers = new HttpHeaders({
            authorization : 'Bearer ' + localStorage.getItem('userData')
		 });

        var currentDateTime = new Date();		
		var aestCreatedEndDate = moment.utc(currentDateTime).tz("Australia/Brisbane").format("yyyy-MM-DDTHH:mm:ss");
        var aestCreatedStartDate = moment(aestCreatedEndDate).subtract(10, 'months').format("yyyy-MM-DDTHH:mm:ss");
        aestCreatedEndDate = aestCreatedEndDate + ".000Z"; 
        aestCreatedStartDate = aestCreatedStartDate + ".000Z"; 

        return this.http.get<any>(ApiEndpoints.BEACHREPORTFETCH+APP_SETTINGS.GOLDCOAST_WAVE_BUOY+"?endTime="+aestCreatedEndDate+"&limit=1&offset=0&order=TimeDesc&startTime="+aestCreatedStartDate+"&state=Visible", {headers: headers})
        .pipe(map(beachreport => {			
            return beachreport;
        }));
    }

    clearSession() {
        localStorage.removeItem('userData');
		localStorage.removeItem('userInfo');
		localStorage.removeItem('selectedtowerid');
		localStorage.removeItem('selecteddate');
		localStorage.removeItem('brdataset');
		localStorage.removeItem('irdataset');
        localStorage.removeItem('codataset');
        localStorage.removeItem('eqdataset');
        localStorage.removeItem('irdata');
        localStorage.removeItem("towers");
    }

    sortAlphaNumeric = (data, orderBy, key) => {
		const regexNonAlpha = /[^a-zA-Z!@#$%^&*()_+-=]/g;
		const regexNumeric = /[+-]?([0-9]*[.])?[0-9]+/g;
		let order;
	  
		switch (orderBy.toUpperCase()) {
		  case "ASC":
			order = 1;
			break;
		  case "DESC":
			order = -1;
			break;
		  default:
			order = 1;
		}
	  
		const sortedData = data.sort((a, b) => {
		  const itemA = key ? a[key].toString() : a.toString();
		  const itemB = key ? b[key].toString() : b.toString();
		  const aAlpha = itemA.replace(regexNonAlpha, "");
		  const bAlpha = itemB.replace(regexNonAlpha, "");
	  
		  if (aAlpha === bAlpha) {
			let aNum = itemA.match(regexNumeric);
			let bNum = itemB.match(regexNumeric);
			aNum = aNum ? parseFloat(aNum.join("")) : 0;
			bNum = bNum ? parseFloat(bNum.join("")) : 0;
	  
			return aNum === bNum ? 0 : aNum < bNum ? -order : order;
		  }
		  return aAlpha < bAlpha ? -order : order;
		});
	  
		return sortedData;
	  }
}