
import { APP_SETTINGS } from 'src/app/models/app-settings';
export class ApiEndpoints {

    // AUTHENTICATION
    public static get SIGNIN(): string {
        return APP_SETTINGS.ENVIROCOM_API_ENDPOINT + 'api/opintel_v1/auth/authenticate';
    }

    // USER INFORMATION BY TOKEN
    public static get USERINFO(): string {
        return APP_SETTINGS.ENVIROCOM_API_ENDPOINT + 'api/opintel_v1/auth/userfromtoken';
    }

    // TOWERS LIST
    public static get TOWERSLIST(): string {
        
        return APP_SETTINGS.ENVIROCOM_API_ENDPOINT + 'api/opintel_v1/locations?organisation=' + APP_SETTINGS.ORGANIZATION_ID+'&project='+APP_SETTINGS.PROJECT_ID;
    }

    // Beach Report Form Post
    public static get BEACHREPORTPOST(): string {
        return APP_SETTINGS.ENVIROCOM_API_ENDPOINT + 'api/opintel_v1/dataset/';
    }

    // Beach Report Fetch on existing Record
    public static get BEACHREPORTFETCH(): string {
        return APP_SETTINGS.ENVIROCOM_API_ENDPOINT + 'api/opintel_v1/dataset/fetch/';
    }

    // Beach Report Fields mapping
    public static get BEACHREPORTFIELDMAPPINGS(): string {
        return APP_SETTINGS.ENVIROCOM_API_ENDPOINT + 'api/opintel_v1/dataset/summary/fieldmapping?dataset=';
    }

    // Search Dataset by TowerId
    public static get DATASETBYTOWER(): string {
        return APP_SETTINGS.ENVIROCOM_API_ENDPOINT + 'api/opintel_v1/dataset/search?organization='+APP_SETTINGS.ORGANIZATION_ID+'&project='+APP_SETTINGS.PROJECT_ID;
    }

    // Search Dataset by TowerId - Beach Report
    public static get DATASETBYTOWERFORBEACHREPORT(): string {
        return APP_SETTINGS.ENVIROCOM_API_ENDPOINT + 'api/opintel_v1/dataset/search?organization='+APP_SETTINGS.ORGANIZATION_ID+'&project='+APP_SETTINGS.PROJECT_ID_BEACH_REPORTS;
    }

    // Search Dataset by TowerId - Condition Report
    public static get DATASETBYTOWERFORCONDITIONREPORT(): string {
        return APP_SETTINGS.ENVIROCOM_API_ENDPOINT + 'api/opintel_v1/dataset/search?organization='+APP_SETTINGS.ORGANIZATION_ID+'&project='+APP_SETTINGS.PROJECT_ID_CONDITIONS_REPORT;
    }

    // Search Dataset by TowerId - Equipment Report
    public static get DATASETBYTOWERFOREQUIPMENTREPORT(): string {
        return APP_SETTINGS.ENVIROCOM_API_ENDPOINT + 'api/opintel_v1/dataset/search?organization='+APP_SETTINGS.ORGANIZATION_ID+'&project='+APP_SETTINGS.PROJECT_ID_EQUIPMENT_REPORT;
    }

    // Search Dataset by TowerId - Incident Report
    public static get DATASETBYTOWERFORINCIDENTREPORT(): string {
        return APP_SETTINGS.ENVIROCOM_API_ENDPOINT + 'api/opintel_v1/dataset/search?organization='+APP_SETTINGS.ORGANIZATION_ID+'&project='+APP_SETTINGS.PROJECT_ID_INCIDENT_REPORT;
    }

    // View Image by Form Field and Beach Report Id
    public static get IMAGEPREVIEW(): string {
        return APP_SETTINGS.ENVIROCOM_API_ENDPOINT + 'api/opintel_v1/dataset/preview/';
    }

    // View Video by Form Field and Beach Report Id
    public static get VIDEOPREVIEW(): string {
        return APP_SETTINGS.ENVIROCOM_API_ENDPOINT + 'api/opintel_v1/dataset/previewvideo/';
    }

    // FORGOT PASSWORD USER VERIFICATION
    public static get FORGOTPWDVERIFICATION(): string {
        return APP_SETTINGS.ENVIROCOM_API_ENDPOINT + 'api/opintel_v1/auth/forgotpwd/verify';
    }

    // FORGOT PASSWORD EMAIL / OTP VALIDATION
    public static get FORGOTPWDEMAILOTPVALIDATION(): string {
        return APP_SETTINGS.ENVIROCOM_API_ENDPOINT + 'api/opintel_v1/auth/forgotpwd/otpvalidate';
    }

    // FORGOT PASSWORD EMAIL / OTP VALIDATION
    public static get FORGOTPWDUPDATEPASSWORD(): string {
        return APP_SETTINGS.ENVIROCOM_API_ENDPOINT + 'api/opintel_v1/auth/forgotpwd/updatepassword';
    }
}


